@use '../utilities/variables';
@use 'sass-mq';

// --------------- //
// --- ARTICLE --- //
// --------------- //

main.article,
main.article .inner-wrapper {
  max-width: 800px;
}

main.article {
  .info {
    padding: 2rem;
    background-color: variables.$faded-blue;
    font-size: 1rem;
  }
}

.authoring {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-style: italic;
}

.resources {
  padding-top: 2rem;
  font-size: 0.8rem;
  border-top: 1px solid variables.$light-grey;
}

code[class*='language-'],
pre[class*='language-'] {
  font-family: 'Operator Mono SSm A', 'Operator Mono SSm B', Consolas, Monaco,
    'Andale Mono', 'Ubuntu Mono', monospace;

  .token.keyword {
    font-weight: 500;
  }

  .token.comment {
    font-style: italic;
  }

  .token.operator {
    background: transparent;
  }
}

.published-at {
  text-align: center;
  font-size: 1rem;
  opacity: 0.9;
  margin: 1rem 0;
}

aside.cta {
  padding: 1rem 2rem;
  text-align: center;
  background-color: variables.$faded-blue;

  div[role='heading'] {
    font-size: 1.2rem;
  }
}
