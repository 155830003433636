@use '../utilities/variables';
@use 'sass-mq';

// Call to action
a.cta,
button.cta {
  text-align: center;
  font-family: variables.$bold;
  border: 0;
  display: inline-block;
  text-decoration: none;
  color: variables.$white;
  background-color: variables.$brand;
  border-radius: 9999px;
  padding: 1rem 2rem;
  cursor: pointer;

  box-shadow:
    rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  transition: transform 250ms cubic-bezier(0.1, 0.2, 0.3, 0.4);

  #{variables.$link-states} {
    // text-decoration: underline;
    filter: brightness(120%);
    transform: scale(1.1);
  }
}

.button {
  display: inline-block;
  border: 1px solid variables.$grey;
  text-decoration: none;
  color: variables.$white;
  background-color: variables.$brand;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;

  transition: transform 250ms cubic-bezier(0.1, 0.2, 0.3, 0.4);

  #{variables.$link-states} {
    text-decoration: none;
    background-color: variables.$blue;
  }
}

.button__light {
  background-color: variables.$faded-blue;
  color: variables.$flashy-blue;

  #{variables.$link-states} {
    text-decoration: none;
    border-color: variables.$flashy-blue;
  }
}

// @include sass-mq.mq($until: tablet) {
//   .cta {
//     margin: variables.em(70, 12) 0 variables.em(40, 12);

//     a,
//     button {
//       padding: variables.em(14, 12) 0;
//       width: variables.em(228, 12);
//       line-height: variables.lh(16, 12);
//     }
//     span {
//       font-size: variables.em(17, 12);
//     }
//   }
// }

// @include sass-mq.mq(tablet, desktop) {
//   // Call to action
//   .cta {
//     a {
//       padding: variables.em(17, 14) 0;
//       width: variables.em(266, 14);
//       line-height: variables.lh(19, 14);
//     }
//     span {
//       font-size: variables.em(20, 14);
//     }
//   }
// }

// @include sass-mq.mq($from: desktop) {
//   .cta {
//     margin: 2rem 0 3rem;

//     a {
//       padding: variables.em(20, 18) 0;
//       width: variables.em(342, 18);
//       line-height: variables.lh(22, 18);
//     }
//     span {
//       font-size: variables.em(26, 18);
//     }
//   }
// }
