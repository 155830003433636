@use 'utilities/variables';
@use 'sass-mq';

// ------------------ //
// --- TYPOGRAPHY --- //
// ------------------ //

body {
  font-family: 'Urbanist regular', Arial;
}

// Titles
h1 {
  position: relative;
  margin: 0;
  font-family: variables.$bold;
  text-align: center;
  // text-transform: uppercase;
  color: variables.$brand;
  // text-shadow: 0 0 1px $dark-grey;
}
h2,
div[role='heading'][aria-level='2'] {
  margin: 0;
  padding-bottom: 1rem;
  font-family: variables.$bold;
  // text-transform: uppercase;
  // border-bottom: 1px solid variables.$grey;
  text-align: center;
}
h3 {
  margin-bottom: 2rem;
  font-family: variables.$bold;
}

// Links
main a {
  color: variables.$brand;
  #{variables.$link-states} {
    text-decoration: none;
  }
}

// Paragraphs & lists
ul,
ol,
p {
  margin-top: 0;
  margin-bottom: 0;
}
main {
  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Strong
strong {
  font-family: variables.$bold;
  color: variables.$brand;
}

// Time & event
time,
.event {
  &::after {
    content: '\0002F';
    display: inline-block;
    margin-left: 5px;
    font-family: variables.$regular;
    font-weight: normal;
  }
  &:last-child::after {
    content: normal;
  }
}

// Table
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border-bottom: 1px solid variables.$grey;
  text-align: right;
  &:first-child {
    text-align: left;
  }
}

// Thead
thead th {
  font-family: variables.$bold;

  &:nth-child(even) {
    background-color: variables.$grey;
  }
  &:nth-child(3) {
    background-color: rgba(variables.$grey, 0.5);
  }
}
// Tfoot
tfoot {
  position: relative;
  border-top: 1px solid variables.$grey;
  font-family: variables.$extrabold;
  color: variables.$white;
  background-color: variables.$blue;
  // Trick to bypass border-collapse effect
  &::before {
    content: ' ';
    display: block;
    background-color: variables.$white;
  }
  th {
    border-bottom: 0;
    &:nth-child(even) {
      background-color: rgba(variables.$dark-blue, 0.5);
    }
  }
}
// Tbody
td:nth-child(even) {
  background-color: variables.$light-grey;
}

// Cite
cite {
  font-style: normal;
  // &::after {
  //   content: '\02013';
  //   display: inline-block;
  //   margin-left: 5px;
  //   font-family: variables.$regular;
  // }
  &:last-child::after {
    content: normal;
  }
}

// Address
address {
  font-style: normal;
}

// Hr
hr {
  border: 0;
  height: 1px;
  background-color: variables.$grey;
}

// Code
code,
tt {
  // display: inline-block;
  // margin: 0 2px;
  // padding: 0 5px;
  // border: 1px solid $grey;
  // vertical-align: 1px;
  font-family: 'Operator Mono SSm A', 'Operator Mono SSm B', Consolas,
    'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
    'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono',
    'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
  color: variables.$brand;
  // background-color: $white;
}

pre code,
h1 code,
h2 code,
h3 code,
h4 code,
h5 code {
  border: 0;
}

h3,
h4 {
  font-family: variables.$bold;
}

// Label
label {
  display: inline-block;
  // font-family: $bold;
  // &::after {
  //   content: '\0003A';
  //   display: inline-block;
  //   margin-left: 5px;
  // }
}

// --- Mobile --- //

@include sass-mq.mq($until: tablet) {
  body {
    font-size: variables.rem(12);
    line-height: variables.lh(20, 12);
  }
  // Titles
  h1 {
    padding: variables.em(25, 26) 0;
    font-size: variables.rem(26);
    // line-height: lh(30, 26);
  }
  h2 {
    margin: variables.em(19, 17) variables.em(20, 17) variables.em(20, 17);
    padding: variables.em(10, 17) 0;
    font-size: variables.rem(17);
    line-height: variables.lh(20, 17);
  }
  h3 {
    font-size: variables.rem(15);
    line-height: variables.lh(20, 15);
  }
  h4 {
    font-size: variables.rem(13);
    line-height: variables.lh(20, 13);
  }
  h5 {
    font-size: variables.rem(11);
    line-height: variables.lh(20, 11);
  }
  // Paragraphs & lists
  main {
    p,
    ul,
    ol,
    table {
      margin-bottom: variables.em(20, 12);
    }
  }
  // Table
  th,
  td {
    padding: variables.em(5, 12) variables.em(10, 12);
  }
  // Trick to bypass border-collapse effect
  tfoot::before {
    height: variables.em(5, 12);
  }
  // Hr
  hr {
    margin: variables.em(20, 12) 0;
  }
}

// --- Tablet --- //

@include sass-mq.mq(tablet, desktop) {
  body {
    font-size: variables.rem(14);
    line-height: variables.lh(24, 14);
  }
  // Titles
  h1 {
    font-size: variables.rem(30);
    // line-height: lh(96, 30);
  }
  h2 {
    margin: variables.em(21, 20) variables.em(20, 20) variables.em(24, 20);
    font-size: variables.rem(20);
    line-height: variables.lh(50, 20);
  }
  h3 {
    font-size: variables.rem(17);
    line-height: variables.lh(24, 17);
  }
  // Paragraphs & lists
  main {
    p,
    ul,
    ol,
    table {
      margin-bottom: variables.em(24, 14);
    }
  }
  // Table
  th,
  td {
    padding: variables.em(7, 14) variables.em(10, 14);
  }
  // Trick to bypass border-collapse effect
  tfoot::before {
    height: variables.em(6, 14);
  }
  // Hr
  hr {
    margin: variables.em(24, 14) 0;
  }
}

// --- Desktop --- //

@include sass-mq.mq($from: desktop) {
  body {
    // font-size: rem(18);
    line-height: variables.lh(28, 18);
  }
  // Titles
  h1 {
    margin-top: variables.em(56, 40);
    // padding-bottom: em(56, 40);
    font-size: variables.rem(40);
    // line-height: lh(126, 40);
    &::after {
      right: 0;
      bottom: variables.em(-19, 40);
      // bottom: 0;
      left: 0;
      height: variables.em(10, 40);
      background-color: variables.$grey;
    }
  }
  h2 {
    margin: variables.em(18, 26) variables.em(30, 26) variables.em(28, 26);
    font-size: variables.rem(26);
    // line-height: lh(65, 26);
  }
  h3 {
    font-size: variables.rem(22);
    line-height: variables.lh(28, 22);
  }
  // Paragraphs & lists
  main {
    p,
    ul,
    ol,
    table {
      margin-bottom: variables.em(28, 18);
    }
  }
  // Table
  th,
  td {
    padding: variables.em(7, 18) variables.em(15, 18);
  }
  // Trick to bypass border-collapse effect
  tfoot::before {
    height: variables.em(7, 18);
  }
  // Hr
  hr {
    margin: variables.em(28, 18) 0;
  }
}

.stamp {
  font-size: 80%;
  opacity: 0.8;
  font-style: italic;
}

.regularTables .inner-wrapper {
  thead th {
    background-color: #eae3db;
    text-align: left;
  }

  tbody td {
    text-align: left;
    background-color: white;
  }

  tbody > tr:nth-child(even) td {
    background-color: #f8f6f2;
  }
}
